
.apitoken-prompt {
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.apitoken-prompt a {
  color: orange;
}

.apitoken-prompt > .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.apitoken-prompt > .content > input {
  min-width: 300px;
  padding: .5rem;
}


.apitoken-prompt > .version {
  position: fixed;
  bottom: 0;
}

.button-as-link {
  appearance: none;
  text-decoration: underline;
  background: none;
  border: none;
  color: orange;
  opacity: 1;
  display: block;
  padding: 1rem 0;
  cursor: pointer;
}

.button-element.button-element {
  display: inline-block;
  padding: .5rem 1rem;
  border-radius: 4px;
  background: orange;
  color: black;
  text-decoration: none;
  border: 0;
}

.input-element {
  padding: 0.5rem;
}
