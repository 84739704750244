*, *::after, *::before {
  box-sizing: border-box;
}

body {
  background-color: rgb(20, 20 ,20);
}

.app-layout {
  --panelSize: 400px;

  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;

  /* perspective-origin: 0; */
  transform-style: preserve-3d;
  perspective: 1000px;
}

.issues-picker {
  flex: 0;
  min-width: var(--panelSize);
  box-shadow: 10px 10px 20px rgba(0, 75, 125, 0.1);
  z-index: 10;
  position: relative;
  height: 100%;
  max-height: 100vh;
  background: white;

  overflow-y: auto;
  z-index: 10;
}

@media screen and (max-width: 800px) {
  body {
    overflow-x: hidden;
  }

  .app-layout {
    width: calc(100% + var(--panelSize) - 64px);
  }

  .app-layout > .issues-picker,
  .app-layout > .scheduler-container {
    transform: translate(calc(-1 * (var(--panelSize) - 64px)));
    transition: .4s ease-in-out;
  }

  .issues-picker:hover, .issues-picker:hover + .scheduler-container {
    transform: translate(0);
  }

}

.scheduler-container {
  flex: 1;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  transition: all .2s cubic-bezier(.21,1.35,.71,.98);
  padding-left: var(--panelSize);
  margin-left: calc(-1 * var(--panelSize));
  z-index: 0;
  transform: translateZ(0);
}

.app-layout.-hasOpenedEvent .scheduler-container {
  transform: translateZ(-50px);
  opacity: 0.2;
  filter: blur(8px);
  background: white;
}

.issue-modal-container {
  z-index: 10;
  position: fixed;
  top: 0;
  left: calc(8px + var(--panelSize) + 8px);
  right: 0;
  bottom: 0;
  max-height: 100vh;
  padding: 128px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  transition: all .2s cubic-bezier(.21,1.35,.71,.98);

  opacity: 0;
  transform: translateY(100px) scale(0.5);
  pointer-events: none;
}

.app-layout.-hasOpenedEvent .issue-modal-container {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.issue-card {
  max-width: 480px;
  border-radius: 4px;
  width: 100%;
  min-height: 320px;
  background-color: white;
  padding: 16px;
}
